.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.container main {
    width: 100%;
    height: calc(100% - 50px);
}