.container {
    position: relative;
    height: 100%;
    color: black;
    font-weight: bold;
}

.container[data-status='avis'] {
    background-color: yellow;
}

.container[data-status='arrived'] {
    background-color: black;
    color:white !important;
}

.container[data-status='cancelled'] {
    background-color: gray;
}

.container[data-status='depatured'] {
    background-color: black;
}

.container[data-status='refused'] {
    background-color: red;
}

.container > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}