.container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container > div:first-child {
    height: 30px;
    font-size: 25px;
    color: black;
    font-weight: 400;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.container > div:last-child {
    overflow: auto;
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}


.responsiveTable {
    width: 100%;
}

.responsiveTable thead {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.responsiveTable thead th {
    background-color: white;
    border: 1px solid #878787;
    font-weight: normal;
    text-align: center;
    color: black;
}

.responsiveTable thead th:first-of-type {
    text-align: left;
}

.responsiveTable tbody,
.responsiveTable tr,
.responsiveTable th,
.responsiveTable td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
}

.responsiveTable th,
.responsiveTable td {
    padding: 3px 5px;
    vertical-align: middle;
}

.responsiveTable caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}

.responsiveTable tfoot {
    font-size: .8em;
    font-style: italic;
}

.responsiveTable tbody tr {
    margin-bottom: 5px;
    border: 1px solid #878787;
}

.responsiveTable tbody tr:last-of-type {
    margin-bottom: 0;
}

.responsiveTable tbody th[scope="row"] {
    background-color: white;
    color: black;
    border-bottom: 1px solid #878787;
}

.responsiveTable tbody td[data-type=currency] {
    text-align: right;
}

.responsiveTable tbody td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: .8em;
    color: rgba(94, 93, 82, 0.75);
}

.responsiveTable tbody td {
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}


@media (min-width: 52em) {
    .responsiveTable {
        font-size: .9em;
    }

    .responsiveTable thead {
        position: relative;
        clip: auto;
        height: auto;
        width: auto;
        overflow: auto;
    }

    .responsiveTable tr {
        display: table-row;
    }

    .responsiveTable th,
    .responsiveTable td {
        display: table-cell;
        padding: 3px 5px;
    }

    .responsiveTable caption {
        font-size: 1.5em;
    }

    .responsiveTable tbody {
        display: table-row-group;
    }

    .responsiveTable tbody tr {
        display: table-row;
        border-width: 1px;
    }

    .responsiveTable tbody th[scope="row"] {
        background-color: transparent;
        color: black;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        border-right: 1px solid #878787;
        width: 250px;
    }

    .responsiveTable tbody td {
        text-align: center;
    }

    .responsiveTable tbody td[data-title]:before {
        content: none;
    }
}

@media (min-width: 62em) {
    .responsiveTable {
        font-size: 1em;
    }

    .tableContainer {
        width: 650px !important;
    }
}

@media (min-width: 75em) {
    .responsiveTable th,
    .responsiveTable td {
        padding: 3px 5px;
    }
}


@media screen and (max-width: 600px) {
    .container > div:last-child {
        padding: 10px 5px;
    }

    .container > div:first-child {
        font-size: 15px;
    }
}

.tablePreLabel {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
}

.tableContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}