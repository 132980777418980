.input {
    height: 30px;
    border: 1px solid #878787;
    border-radius: 2px;
    font-size: 15px;
    padding: 2px 5px 2px 5px;
    width: 100%;
    cursor: pointer;
}

.input[data-type='search'] {
    padding: 5px 5px 5px 27px;
}

.input:disabled {
    cursor: not-allowed;
}

.input:focus-visible, .input:focus {
    border-color: dodgerblue;
    outline: none;
}

.input::placeholder {
    font-size: 15px;
}

.searchBtn {
    position: absolute;
    left: 7px;
    top: 7px;
    font-size: 14px;
    color: darkgray;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input[type=number] {
    -moz-appearance: textfield;
}