.withProgressBarContainer {
    height: 150px;
}

.container {
    height: 50px;
}

.btnRow {
    height: 50px;
}