.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.button {
    position: relative;
    min-width: 85px;
    border: none;
    border-radius: 2px;
    background-color: var(--main-light-blue-color);
    color: white;
    height: 30px;
    font-size: 14px;
    padding-right: 5px;
}

.button:hover {
    outline: 1px solid lightblue;
}

.buttonIconPadding {
    padding-left: 30px;
}

.icon {
    position: absolute;
    left: 7px;
    top: 7px;
    font-size: 14px;
}

.icon svg {
    color: white;
}