.container {
    width: 100%;
    height: 100%;
    display: flex;
}

.documentContainer {
    width: 40%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.documentContainer img {
    width: 400px;
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.textContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.textContainer > div:first-child {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.textContainer > div:first-child > div:first-child {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: black;
    letter-spacing: 3px;
}

.textContainer > div:last-child {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.textContainer > div:last-child > div:first-child {
    color: #7b7b7b;
    font-weight: bold;
}

@media screen and (max-width: 1000px) {
    .documentContainer {
        display: none;
    }

    .textContainer {
        width: 100%;
    }
}

@media screen and (max-height: 450px) {
    .documentContainer {
        display: none;
    }

    .textContainer {
        width: 100%;
    }
}
