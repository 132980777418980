.container {
    display: table;
    width: 100%;
    height: 80px;
    table-layout: fixed;
    position: relative;
}

.step {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    overflow: visible;
    position: relative;
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.description {
    color: #82888C;
    font-size: 9.0pt;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container > div:not(:last-child):before {
    content: '';
    display: block;
    position: absolute;
    left: 52%;
    top: 20px;
    background-color: #ccc;
    height: 3px;
    width: 100%;
}

.node {
    display: inline-block;
    border: 6px solid #ccc;
    background-color: #ccc;
    border-radius: 18px;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.complete .node {
    background-color: black;
    border-color: black;
}

.complete:before {
    background-color: black !important;
}

.progress:before {
    background: black;
    background: -moz-linear-gradient(left, black 0%, #ccc 100%);
    background: -webkit-linear-gradient(left, black 0%, #ccc 100%);
    background: linear-gradient(to right, black 0%, #ccc 100%);
}

.progress .node {
    background-color: black;
    border-color: black;
}

.textContainer {
    height: 30px;
}

.progress .icon {
    color: #00c0ff;
}

.complete .icon {
    color: var(--main-light-green-color);
}

.icon {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    font-size: 15px;
}

.overflowHidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}