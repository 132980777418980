.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1px;
}

.container > a:last-child {
    margin-right: 1px;
}

.menuItem {
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 70%;
    border: 1px solid rgba(255, 255, 255, .2);
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 2px;
}

.menuItem > span:first-child {
    width: 30px;
    font-size: 15px;
    text-align: center;
}

.menuItem > span:last-child {
    padding-right: 5px;
}

.menuItem:hover:not(.selected) {
    border-color: var(--main-light-blue-color);
}

.selected {
    border-color: var(--main-light-green-color);
}

@media screen and (max-width: 600px) {
    .menuItem {
        min-width: 45px;
        justify-content: center;
        height: 45px;
    }

    .menuItem > span:last-child {
        display: none;
    }
}

.separateMenuHeaderItemMargin {
    margin: 0 20px;
}