.iconButtonContent {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.iconButtonContent svg {
    font-size: 20px;
}