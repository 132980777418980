.container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
}

.container > div:first-child {
    height: 30px;
    font-size: 25px;
    color: black;
    font-weight: 400;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.container > div:last-child {
    overflow: hidden;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
}

.contentContainer {
    display: flex;
    flex-direction: column;
}

.contentContainer > div:first-child {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentLabel {
    font-size: 20px;
}

.contentContainer > div:last-child {
    overflow: auto;
    flex: 1 1;
    border: 1px solid #ccc;
    margin: 5px;
    padding: 2px;
}

.btn {
    display: flex;
    font-size: 18px;
    margin: 7px 10px 7px 0;
}

.btn > span:first-child {
    margin: 0 10px;
}

@media screen and (max-width: 680px) {
    .contentContainer > div:first-child {
        flex-direction: column;
        gap: 5px;
    }
}

@media screen and (max-width: 600px) {
    .container > div:first-child, .contentLabel {
        font-size: 17px;
    }
}


.table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.table tr {
    background-color: white;
    border: 1px solid #ddd;
}

.table th,
.table td {
    padding: 5px;
    text-align: center;
}

.table td {
    border-right: 1px solid #ddd;
}

.table th {
    font-size: 15px;
    font-weight: bold;
    padding: 7px !important;
    border-right: 1px solid #ccc;
}

.table th:last-child {
    border-right: none;
}

.refuseNotesRow {
    border-top: 2px solid darkred !important;
    border-left: 2px solid darkred !important;
    border-right: 2px solid darkred !important;
    margin-bottom: 0 !important;
}

.refusedBorder {
    border-left: 2px solid darkred !important;
    border-bottom: 2px solid darkred !important;
    border-right: 2px solid darkred !important;
}

.refuseNotesRow > td {
    background-color: #930000 !important;
    border-right: 2px solid darkred;
    color: white !important;
    font-weight: bold !important;
    text-align: left !important;
}

.separatorRow {
    height: 5px;
}

@media screen and (max-width: 600px) {
    .container > div:first-child {
        font-size: 17px;
    }

    .separatorRow {
        display: none !important;
    }

    .table {
        border: 0;
    }

    .table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .table tr {
        display: block;
        border: 1px solid black;
        margin-bottom: 5px;
    }

    .table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .table td:last-child {
        border-bottom: 0;
    }

    .deleteIcon > svg {
        font-size: 20px;
    }
}

.deleteIcon > svg {
    cursor: pointer;
    color: red;
}

.inputContainer {
    min-height: 65px;
}