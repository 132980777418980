.header {
    height: 50px;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 470px calc(100% - 470px);
    background-color: #222;

}

.logoColumn {
    display: grid;
    grid-template-columns: 60px 120px 290px;
    cursor: pointer;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

}

.logoContainer > div {
    width: 45px;
    height: 43px;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer > div > div {
    transform: scale(0.6);
}

.textContainer {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;
    -webkit-user-select: none;
}

.companyNameContainer {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-select: none;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 2px;
    padding: 0 5px;
    margin: 5px 5px;
    font-size: 13px;
    text-align: center;
    border: 1px solid #a3cb6a;
}

@media screen and (max-width: 450px) {
    .header {
        grid-template-columns: 60px calc(100% - 60px);
    }

    .textContainer {
        display: none;
    }
}

@media screen and (max-width: 1040px) {
    .companyNameContainer {
        display: none;
    }
}

.headerMenuLabel {
    font-size: 14px;
    font-style: normal;
    color: white;
    font-weight: 400;
    display: flex;
    justify-content: end;
    height: 100%;
    align-items: center;
    padding: 0 10px;
    text-align: right;
}
