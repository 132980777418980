.button {
    padding: 8px;
    font-size: 13px;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.2s ease-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.button:disabled {
    cursor: not-allowed;
    background-color: gray !important;
}

.button:hover {
    transform: translateY(-0.5px);
}

.button[data-type='warning'] {
    background-color: #e54c27;
}

.button[data-type='primary'] {
    background-color: var(--main-light-green-color);
}

.button[data-type='secondary'] {
    background-color: var(--main-dark-blue-color);
}

.button[data-type='error'] {
    background-color: red;
}

.button[data-type='action'] {
    background-color: black;
    color: white;
}

.loadingSpan {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 10px;
}

.loadingSpan svg {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    font-size: 15px;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}