.label {
    font-size: 12px;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border-radius: 2px;
}

.label[data-type='warning'] {
    background-color: #e54c27;
    border: 1px solid red;
}

.label[data-type='information'] {
    background-color: #d9edf7;
}

.center {
    text-align: center;
}

.animation {
    animation: anim 0.15s ease-in;
    -webkit-animation: anim 0.15s ease-in;
}

@keyframes anim {
    0% {
        opacity: 0.5;
        scale: 0.9;
    }

    100%{
        opacity: 1;
        scale: 1;
    }
}

@-webkit-keyframes anim {
    0% {
        opacity: 0.5;
        scale: 0.4;
    }

    100%{
        opacity: 1;
        scale: 1;
    }
}