.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.container > div:first-child {
    text-align: center;
    margin-top: 12px;
}

.container hr {
    margin: 15px 0;
    opacity: 1;
}

.labelContainer {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
}

.iconButtonContent {
    display: flex;
    gap: 15px;
    align-items: center;
    font-weight: bold;
}

.iconButtonContent svg {
    font-size: 40px;
}

.loadingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.margin20 {
    margin: 20px 0;
}

.fs30 {
    font-size: 30px;
}

.fs18 {
    font-size: 18px;
}

.bold {
    font-weight: bold;
}

.center {
    text-align: center;
}

.ruleContainer {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.ruleContainer > div {
    margin: 10px 0;
}

@media screen and (max-width: 786px) {
    .ruleContainer {
        width: 90%;
        margin-right: inherit;
    }
}