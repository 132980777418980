.container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card {
    width: 450px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 2px;

    animation: fadeIn 1s ease-in both;
    -webkit-animation: fadeIn 1s ease-in both;
}

.card form {
    color: white;
}

.card form h4 {
    margin: 35px 0;
    text-align: center;
}

.controlContainer {
    padding: 0 20px;
    margin-bottom: 20px;
}

.label {
    text-align: center;
    margin: 35px 0;
}

.footer {
    backdrop-filter: blur(3px) saturate(15%);
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    user-select: none;
    padding: 0 10px;
}

.footer > span:nth-child(2) {
    font-weight: bold;
}

.logoContainer {
    position: absolute;
    left: 30px;
    top: 20px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 10px;
}

@keyframes fadeIn {
    0% {
        opacity: 0.3;
        transform: translate3d(0, -10%, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0.3;
        transform: translate3d(0, -10%, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.center {
    text-align: center;
}

.errorLabel {
    font-size: 13px;
    color: black;
    background-color: rgba(255, 0, 0, .2);
}

.languageSwitcherContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 5px;
}