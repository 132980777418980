.container {
    display: flex;
    font-size: 14px;
}

.container[data-theme="white"] {
    color: #686868;
}

.container[data-theme="transparent"] {
    color: white;
}

.container[data-theme="white"] > div {
    border: 1px solid #ccc;
}

.container[data-theme="transparent"] > div {
    border: 1px solid #3d3d3d;
}

.container > div:not(:last-child):not(:first-child) {
    border-left: none;
    border-right: none;
}

.container > div:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.container > div:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.container > div {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 4px 4px 4px 32px;
    position: relative;
    height: 26px;
}

.container > div:hover {
    border-color: var(--main-light-green-color) !important;
}

.container > div[data-disabled='true'] {
    cursor: not-allowed;
}

.container[data-theme="white"] > div {
    background-color: white;
}

.container[data-theme="transparent"] > div {
    background-color: rgba(0, 0, 0, .2);
}

.container[data-theme="white"] > div[data-state="selected"] {
    background-color: #ddd;
}

.container[data-theme="transparent"] > div[data-state="selected"] {
    background-color: rgba(0, 0, 0, .5);
}

.img {
    position: absolute;
    left: 6px;
    top: 2px;
}

.img img {
    width: 20px;
}

.hideTextImgContainer {
    left: 8px;
}