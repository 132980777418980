.container {
    display: grid;
    grid-template-rows: 35px calc(100% - 78px) 43px;
    height: 100%;
}

.header {
    border-bottom: 1px solid #e0e0e0;
    display: grid;
    grid-template-columns: calc(100% - 40px) 40px;
}

.header > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header > div:first-child {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
}

.loadingContainer {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
}

.main {
    padding: 10px;
}

.close {
    color: #878787;
    cursor: pointer;
    font-size: 22px;
}

.close:hover {
    color: red;
    opacity: 0.9;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px 10px;
    border-top: 1px solid #e0e0e0;
}
