.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.loadingScreenContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
    z-index: 1001;
}