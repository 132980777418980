.h100 {
    height: 100%;
    position: relative;
}

.secondColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    height: 100%;
}

.bottomConfirmationLabelContainer {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 576px) {
    .secondColumn {
        gap: 5px;
    }
}