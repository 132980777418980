.container {
    padding: 10px;
    height: 100%;
}

.search_period{
    padding: 10px;
    flex: 1;
    align-content: center;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;


}

.selected_search_period {
    border-radius: 2px;
    text-decoration: none;
    font-weight: bold;
    border: solid 2px var(--main-light-green-color);
}

.search_period_container {
    display: flex;
}
