.container {
    height: 55px;
}

.inputContainer {
    display: flex;
}

.inputContainer > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border: none;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    color: var(--main-light-green-color);
    background-color: rgba(0, 0, 0, 0.4);
}

.inputContainer > span > svg {
    font-size: 14px;
}

.input {
    width: 100%;
    padding: 6px 5px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    color: white;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);

    border-radius: 2px 0px 0px 2px;
}

.input:focus-visible {
    outline: none;
    border-color: #36ceff;
}

.input::placeholder {
    color: #e5e5e5;
    font-size: 12px;
}

.input:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}


.input[type='checkbox'] {
    width: 18px;
    height: 18px;
}


.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}

.errorContainer {
    background-color: rgba(255, 255, 255, .4);
    border-radius: 1px;
    padding: 0 2px;
}