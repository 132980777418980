.container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container > div:first-child {
    height: 30px;
    font-size: 25px;
    color: black;
    font-weight: 400;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.container > div:last-child {
    overflow: auto;
    height: calc(100% - 30px);
}

.clientInfo {
    border: 1px solid #878787;
    border-radius: 2px;
    font-size: 15px;
    width: 100%;
    padding: 5px;
}

.clientInfo > div {
    margin: 2px 0;
}

.columnHeader {
    font-size: 17px;
    font-weight: bold;
    margin: 10px 0;
}


.afterLabel {
    font-size: 12px;
}


.h100 {
    height: 100%;
}

.brBlack {
    border-right: 1px solid black;
}

.center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 575px) {
    .brBlack {
        border-bottom: 1px solid black;
        border-right: none;
    }

    .center {
        display: block;
    }

    .col {
        margin: 20px 0;
    }
}