.dialogLayout {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows:30px calc(100% - 30px);
}

.dialogLayout > div:first-child {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.dialogLayout > div:first-child > span:first-child {
    font-size: 15px;
    font-weight: bold;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
}

.dialogLayout > div:first-child > span:last-child {
    cursor: pointer;
    color: black;
    font-size: 35px;
}

.dialogLayout > div:first-child > span:last-child:hover {
    color: red;
}