.container {
    grid-template-columns: 25% 25% 25% 25%;
    display: grid;
    padding: 10px;
    border-bottom: solid 1px lightgrey;
}
.row {
    padding: 10px;
    text-align: center;
}
.cell {
    padding: 10px;
    text-align: center;
}
.container > div {
    padding: 10px;
}
