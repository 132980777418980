:root {
    --bg-color: white;
    --text-color: black;

    --main-light-green-color: #a3cb6a;
    --main-light-blue-color: #64c5c3;
    --main-dark-blue-color: #222245;
}

.dark {
    --bg-color: #1d1f27;
    --text-color: white;
}

.server-error-label {
    font-size: 11px;
    color: red;
    font-weight: bold;
    margin: 2px 0;
}
.server-info-label {
    font-size: 11px;
    color: blue;
    font-weight: bold;
    margin: 2px 0;
}

.page-container {
    padding-top: 10px;
}

.required:after {
    content: "*";
    color: red;
    margin: 0 2px;
}

.big.required:after {
    font-size: 18px;
}

.input-container {
    margin-top: 15px;
}

.label {
    font-size: 15px;
    font-weight: 400;
    margin: 2px 0;
}


.syncfusion-control-input-container {

}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    font-family: inherit;
    font-size: 15px;
}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    border: 1px solid #878787 !important;
    border-radius: 2px !important;
}

.syncfusion-control-input-container input.e-input, .syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input {
    padding-left: 5px;
}

.syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input {
    min-height: 25px;
}

.syncfusion-control-input-container input.e-input, .syncfusion-control-input-container .e-input-group input.e-input, .syncfusion-control-input-container .e-input-group input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper input, .syncfusion-control-input-container .e-input-group.e-input-focus input.e-input, .syncfusion-control-input-container .e-input-group.e-control-wrapper.e-input-focus input.e-input {
    padding-left: 5px !important;
}

.syncfusion-control-input-container .e-input-group, .syncfusion-control-input-container .e-input-group.e-control-wrapper {
    margin-bottom: 0;
}

.syncfusion-control-input-container .e-input-group .e-input-group-icon:last-child, .syncfusion-control-input-container .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    margin-right: 5px;
    margin-top: 4px;
}

.toast-body-class {
    font-size: 12px;
}

.list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.list-container .e-grid .e-gridheader tr:first-child th {
    border-right: 1px solid #ccc;
}

.list-container .e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
    padding: 0;
}

.list-container .e-grid .e-rowcell {
    padding: 0px !important;
}

.e-pager .e-pagerexternalmsg {
    display: none !important;
}


.oi-list-container {
    width: 100%;
    height: 100%;
}

.oi-list-container .e-grid .e-gridheader tr:first-child th:not(:last-child) {
    border-right: 1px solid #ccc;
}

.oi-list-container .e-grid .e-gridheader tr:first-child th {
    height: 30px;
}

.oi-list-container .e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
    padding: 0;
}

.oi-list-container .e-grid .e-rowcell {

}

.oi-list-container .e-grid td.e-active {
    background-color: #e8e8e8;
}

.oi-list-container .e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
    box-shadow: none;
}
