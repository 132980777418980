.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.button {
    position: relative;
    min-width: 85px;
    border: none;
    border-radius: 2px;
    background-color: var(--main-light-blue-color);
    color: white;
    height: 30px;
    font-size: 14px;
    padding-right: 5px;
}

.button:hover {
    outline: 1px solid lightblue;
}

.buttonIconPadding {
    padding-left: 30px;
}

.icon {
    position: absolute;
    left: 7px;
    top: 7px;
    font-size: 14px;
}

.icon svg {
    color: white;
}

.loadingContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
    background-color: rgba(0, 0, 0, .3);
}

.input {
    height: 35px;
    border: 1px solid #878787;
    border-radius: 2px;
    font-size: 15px;
    padding: 2px 5px 2px 5px;
    width: 100%;
    cursor: pointer;
    vertical-align: middle;
}

.input[data-type='search'] {
    padding: 5px 41px 5px 41px;
}

.input:disabled {
    cursor: not-allowed;
}

.input:focus-visible, .input:focus {
    border-color: dodgerblue;
    outline: none;
}

.input::placeholder {
    font-size: 15px;
}

.clearBtn {
    position: absolute;
    right: 8px;
    top: -1px;
    font-size: 35px;
    color: red;
    cursor: pointer;
}

.searchBtn {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 25px;
    color: darkgray;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input[type=number] {
    -moz-appearance: textfield;
}

.label {
    margin-bottom: 5px;
    vertical-align: middle;
}

.formrow {
    padding: 5px;
}
